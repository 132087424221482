module.exports = {
  siteTitle: "Resource: Modern Outreach for Recruiting Teams",
  siteTitleShort: "Resource", // PWA, <12 chars
  siteTitleAlt: "Resource.io", // Alternative site title for SEO
  siteLogo: "/images/meta/logo-1024.png", // for SEO and manifest
  siteUrl: "https://resource.io",
  canonicalUrl: "https://resource.io/",
  siteDescription: "Resource: a new standard in recruiting.", // for RSS feeds/meta description
  siteRss: "/rss.xml",
  FBAppID: "",
  googleAnalyticsID: "UA-83142812-2",
  segmentProdKey: "T5IAB68b5kBURDfHrTWD5vk2YN5vRwmR",
  intercomAppId: "rg0xc7fz",
  social: [
    {
      label: "Twitter",
      url: "https://twitter.com/resourceio",
      iconClassName: "fa fa-twitter"
    },
    {
      label: "Email",
      url: "mailto:support@resource.io",
      iconClassName: "fa fa-envelope"
    }
  ],
  copyright: "© 2020 IDK Labs, Inc. All Rights Reserved.",
  themeColor: "#1f1440",
  backgroundColor: "#fff",
};
