/* eslint-env analytics */
import _ from 'lodash';
import React from 'react';
import { push } from 'gatsby-link';

export const newlines = (str) => (
  str.split("<br>").map(i => <span>{i}<br /></span>)
)

export const pageName = () => {
  if (typeof(window) === 'undefined') return '';
  return window.location.pathname || '';
}

export const pathWithParams = (pathname) => {
  if (typeof(window) === 'undefined') return `/${pathname}`;
  const { search } = window.location;
  return search ? `/${pathname}/${search}` : `/${pathname}`;
}

export const isLoggedInUser = () => {
  if (typeof(document) === 'undefined') return '';
  const { cookie = '' } = document;
  return cookie.includes('Meteor.loginToken')
    || cookie.includes('resource-auth-prod');
}

export const identify = (userId = null, props = {}) => {
  if (typeof(analytics) === 'undefined') {
    console.warn('analytics undefined, cant identify');
    return;
  }
  try {
    analytics.identify(userId, props);
  } catch (error) {
    console.log('error:', error)
  }
}

export const trackEvent = (event, props = {}) => {
  if (typeof(analytics) === 'undefined') {
    console.warn('analytics undefined');
    return;
  }
  try {
    analytics.track(event, props);
  } catch (error) {
    console.log('error:', error)
  }
}

// for navigating within the site
export const trackPath = (e, event, pathname, props = {}) => {
  e.preventDefault();
  trackEvent(event, props);
  push(pathname || e.target.pathname);
}

export const trackLink = (e, event, href, props = {}) => {
  e.preventDefault();
  trackEvent(event, props);
  window.location.href = href || e.target.href;
}

export const parseUrlParams = () => {
  if (typeof(window) === 'undefined' || !window.location.search) return null;
  let searchParams;
  try {
    searchParams = JSON.parse(`{"${
      window.location.search
      .replace(/\?/g, '')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')
    }"}`);
  } catch (e) { 
    trackEvent('Parse UTM Param Error', { error: JSON.stringify(e) });
  }

  return searchParams;
}

export const getUtmCookieData = cookies => ['utm_source', 'utm_campaign', 'utm_medium'].reduce((acc, key) => ({
  ...acc,
  [_.camelCase(key)]: cookies.get(key) || '',
}), {})

export const getUtmData = (cookies) => {
  const fromUrl = parseUrlParams();
  if (fromUrl) {
    return _.reduce(fromUrl, (acc, value, key) => ({
      ...acc,
      [_.camelCase(key)]: value || '',
    }), {});
  }
  if (cookies) {
    const fromCookies = getUtmCookieData(cookies);
    if (fromCookies && fromCookies.utmSource) return fromCookies;
  }
  return null;
}
