import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './input.module.scss';

const getPlaceholder = ({ placeholder, label, name = 'Text' }) => {
  if (placeholder || label) return placeholder || label;
  return _.startCase(name.replace(/_/g, ' '));
}

const getLabel = ({ placeholder, label, name = 'Text', noLabel }) => {
  if (noLabel) return null;
  if (placeholder || label) return label || placeholder;
  return _.startCase(name.replace(/_/g, ' '));
}

const getErrorText = ({ required, errorText }) => errorText || (required ? 'Field is required.' : null);

class Input extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.getText = this.getText.bind(this);
    this.placeholder = getPlaceholder(props);
    this.label = getLabel(props);
    this.errorText = getErrorText(props);
    this.inputRef = React.createRef();
    this.state = {
      error: false,
      focused: props.initFocus,
    };
  }

  componentDidMount() {
    const { initFocus } = this.props;
    if (initFocus && this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  onFocus() {
    this.setState({ focused: true });
  }

  onBlur(e) {
    this.setState({ focused: false });
    const { value } = e.target;
    const { required } = this.props;
    if (!required) return;
    this.setState({ error: !value });
  }

  onChange(e) {
    let { value } = e.target;
    const { required, transform, onChange } = this.props;
    if (transform) {
      value = transform(value);
    }
    this.setState({ error: !value && required });
    onChange({ [e.target.name]: value });
  }

  getText() {
    const { helperText, hideErrorState } = this.props;
    const { focused, error } = this.state;
    if (focused) return helperText || ' ';
    if (error && !hideErrorState) return this.errorText;
    return <span>&nbsp;</span>;
  }

  render() {
    const {
      attrs,
      autocomplete,
      className,
      disabled,
      inputClassName,
      name,
      style,
      type,
      value,
      hideErrorState,
    } = this.props;
    const {
      error,
      focused,
    } = this.state;
    
    const text = this.getText();
    const showError = !hideErrorState && error;


    return (
      <div className={cx(styles.formGroup, className, {
        [styles.focused]: focused,
        [styles.error]: showError,
      })}
      >
        { this.label && (
          <p className={cx(styles.label, {
            [styles.populated]: value.length,
            [styles.focused]: focused,
            [styles.error]: showError,
          })}
          >
            { this.label }
          </p>
        )}
        <input
          ref={this.inputRef}
          className={cx(inputClassName, {
            error: showError,
            disabled,
          })}
          style={style}
          type={type}
          value={value}
          name={name}
          autoComplete={autocomplete}
          placeholder={this.placeholder}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          {...attrs}
        />
        <p className={cx(styles.helperText, {
          [styles.error]: showError,
        })}
        >{text}
        </p>
      </div>
    );
  }
}

Input.propTypes = {
  attrs: PropTypes.shape({}),
  autocomplete: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  hideErrorState: PropTypes.bool,
  initFocus: PropTypes.bool,
  inputClassName: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  style: PropTypes.shape({}),
  transform: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.string,
};

Input.defaultProps = {
  attrs: {},
  autocomplete: '',
  className: null,
  disabled: false,
  errorText: null,
  helperText: <span>&nbsp;</span>,
  hideErrorState: false,
  initFocus: false,
  inputClassName: 'w-100',
  label: null,
  required: true,
  style: {},
  transform: null,
  type: 'text',
  value: '',
};

export default Input;
