export const encode = (data) => (
  Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join("&")
)

export const submitToNetlify = ({ data, formName }, cb) => {
  if (!data || !formName) {
    return cb('submitToNetlify error.');
  }
  const body = encode({ "form-name": formName, ...data });
  fetch("/", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body
  })
  .then(() => cb(null, "Success!"))
  .catch(error => {
    console.error(error);
    cb(error);
  });
}