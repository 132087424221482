import React from "react"
import { Helmet } from "react-helmet"
import config from "../../config/site"
import settings from "../settings"

const defaultConfig = (options = {}) => {
  const title = settings.IsInterviewGuide
    ? options.guideTitle
    : options.title || config.siteTitle
  const description = options.description || config.siteDescription
  const defaultMetaConfig = {
    title,
    meta: [
      {
        name: "google-site-verification",
        content: config.googleWebmasterVerification,
      },

      { name: "keywords", content: "recruiting, software" },
      { name: "description", itemprop: "description", content: description },

      { name: "HandheldFriendly", content: "true" },
      { name: "viewport", content: "width=device-width, initial-scale=1.0" },
      { name: "author", content: "Resource" },
      { name: "creator", itemprop: "publisher", content: "IDK Labs, Inc." },
      { property: "og:locale", content: "en_US" },
      { property: "og:type", content: "website" },
    ],
    link: [
      { rel: "canonical", itemprop: "url", href: config.canonicalUrl },
      {
        rel: "apple-touch-icon",
        sizes: "48x48",
        href: "/images/meta/logo-48.png",
      },
      {
        rel: "apple-touch-icon",
        sizes: "128x128",
        href: "/images/meta/logo-128.png",
      },
      { rel: "icon", href: "/images/brand/color/icon.png" },
    ],
  }
  if (settings.IsInterviewGuide) {
    defaultMetaConfig.meta.push({
      property: "og:site_name",
      content: "Guide - A White-Glove Experience for Every Candidate",
    })
    defaultMetaConfig.meta.push({ property: "og:type", content: "website" })
    defaultMetaConfig.meta.push({
      property: "og:url",
      content: "https://candidate.guide/",
    })
    defaultMetaConfig.meta.push({
      property: "og:title",
      content: "Guide - A White-Glove Experience for Every Candidate",
    })
    defaultMetaConfig.meta.push({
      property: "og:description",
      content:
        "Personalize your interview experience for every candidate, no matter where they are.",
    })
    defaultMetaConfig.meta.push({
      property: "og:image",
      content: "/images/meta/share-image__facebook.png",
    })
    defaultMetaConfig.meta.push({
      name: "twitter:domain",
      content: "https://candidate.guide",
    })
    defaultMetaConfig.meta.push({
      name: "twitter:card",
      content: "summary_large_image",
    })
    defaultMetaConfig.meta.push({
      name: "twitter:url",
      content: "https://candidate.guide/",
    })
    defaultMetaConfig.meta.push({
      name: "twitter:title",
      content: "Guide - A White-Glove Experience for Every Candidate",
    })
    defaultMetaConfig.meta.push({
      name: "twitter:description",
      content:
        "Personalize your interview experience for every candidate, no matter where they are.",
    })
    defaultMetaConfig.meta.push({
      name: "twitter:image",
      content: "/images/meta/share-image__twitter.png",
    })
  } else {
    defaultMetaConfig.meta = [
      ...defaultMetaConfig.meta,
      { property: "og:url", content: config.siteUrl },
      { property: "og:title", content: title },
      { property: "og:site_name", content: "Resource" },
      { property: "og:description", content: description },
      { name: "twitter:title", content: title },
      { name: "twitter:description", content: description },
    ]
  }
  return defaultMetaConfig
}

export default ({ options }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Helmet {...defaultConfig(options || {})}>
    <html lang="en" />
  </Helmet>
)
